import {Col, Row} from "antd";
import Logo from "./../layout/images/logo.svg";
import {COLORS} from "../../helpers/constants";
import Facebook from "./image/facebook.svg";
import Youtube from "./image/youtube.svg";
import Gmail from "./image/gmail.svg";
import {FooterText} from "../typography/text";

const defaultStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 10px",
    cursor: "pointer",
    width: 50,
    height: 50,
    borderRadius: 50,
    strokeWidth: "3px",
    stroke: "#FFF",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
}

export const Footer = () => {

    return (
        <Row align={"middle"} style={{width: "100%", padding: 30}} gutter={[16, 16]}>
            <Col xs={10} sm={6} md={6} lg={5} xl={5} xxl={5}>
                <img src={Logo} alt=""/>
            </Col>
            <Col xs={14} sm={18} md={18} lg={18} xl={13} xxl={13}>
                <FooterText color={COLORS.PRIMARY.BLACK}>{new Date().getFullYear() + ".թ"} Կայքը ստեղծվել է միջազգային մասնավոր ձեռնարկատիրության կենտրոնի աջակցությամբ</FooterText>
            </Col>
            <Col md={24} lg={24} xl={6} xxl={6}>
                <div style={{display: "flex"}}>
                    <div style={defaultStyle}>
                        <a href="https://www.facebook.com/ArmPreschoolAssociation/?locale=hy_AM" target={"_blank"}>
                            <img src={Facebook} alt=""/>
                        </a>
                    </div>
                    <div style={defaultStyle}>
                        <a href="https://www.youtube.com/@PreschoolAssociation" target={"_blank"}>
                            <img src={Youtube} alt=""/>
                        </a>
                    </div>
                    <div style={defaultStyle}>
                        <a href="mailto:armassociationpreschool@gmail.com">
                            <img src={Gmail} alt=""/>
                        </a>
                    </div>
                </div>
            </Col>
        </Row>
    )
}