import React from "react";
import {Outlet} from "react-router-dom";
import {MainLayout} from "../components/layout/main-layout";
import DefaultHeader from "../components/layout/DefaultHeader";
import {HeadLine} from "../components/head-line";
import {ContactUs} from "../components/contact-us";
import ToTopButton from "../components/to-top-button";
import {Footer} from "../components/footer";

const Interviews = React.lazy(() => import("../components/videos"));

export const PublicRoutes = () => {

    return (
        <>
            <DefaultHeader/>
            <HeadLine/>
            <MainLayout>
                <Outlet/>
            </MainLayout>

            <React.Suspense>
                <Interviews/>
            </React.Suspense>

            <ContactUs/>

            <Footer/>

            <ToTopButton/>
        </>
    )
};