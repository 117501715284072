import React, {useEffect, useState} from 'react';
import {Menu, Drawer, Button} from 'antd';
import {MenuOutlined} from '@ant-design/icons';
import {DICTIONARY_URL, PATHS, screenSize} from '../../helpers/constants';
import styled from "styled-components";
import useGetDictionary from "../../api/dictionary/useGetDictionary";
import {useNavigate} from "react-router-dom";

const {Item, SubMenu} = Menu; // Corrected import

const StyledMenu = styled(Menu)`
  background: none;
  width: 100%;
  font-weight: bold;
  margin-right: 60px;
`;

export const MyMenu = () => {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const {data: menuItems} = useGetDictionary(DICTIONARY_URL.GET_MENU_ITEMS);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const handleClick = (slug, typeId) => {
        if (typeId === 2) {
            navigate(`${PATHS.POST}/${slug}`, {state: {id: "#post"}})
            setVisible(false);
        } else {
            navigate(`${PATHS.POSTS}/${slug}`, {state: {id: "#posts"}})
            setVisible(false);
        }
    };

    return (
        <div>
            {windowWidth <= screenSize.lg ? (
                <>
                    <Button
                        type="primary"
                        onClick={showDrawer}
                        style={{width: 60, height: 40, top: 10}}
                        className="menu-toggle"
                    >
                        <MenuOutlined/>
                    </Button>
                    <Drawer
                        title="Մենյու"
                        placement="left"
                        closable={false}
                        onClose={onClose}
                        open={visible}
                    >
                        <Menu mode="inline" style={{width: '100%'}}>
                            {menuItems && menuItems.map((item) => (
                                item.sub_categories && item.sub_categories.length > 0 ? (
                                    <SubMenu key={item.id} title={item.name}>
                                        {item.sub_categories.map((subitem) => (
                                            <Item key={subitem.id}
                                                  onClick={() => handleClick(subitem.slug, subitem.type_id)}>
                                                <span>{subitem.name}</span>
                                            </Item>
                                        ))}
                                    </SubMenu>
                                ) : (
                                    <Item key={item.index} onClick={() => handleClick(item.slug, item.name)}>
                                        <span>{item.name}</span>
                                    </Item>
                                )
                            ))}
                        </Menu>
                    </Drawer>
                </>
            ) : (
                <StyledMenu mode="horizontal">
                    {menuItems && menuItems.map((item) => (
                        item.sub_categories && item.sub_categories.length > 0 ? (
                            <SubMenu key={item.slug} title={item.name}>
                                {item.sub_categories.map((subitem) => (
                                    <Item key={subitem.name} onClick={() => handleClick(subitem.slug, subitem.type_id)}>
                                        <span>{subitem.name}</span>
                                    </Item>
                                ))}
                            </SubMenu>
                        ) : (
                            <Item key={item.index} onClick={() => handleClick(item.slug)}>
                                <span>{item.name}</span>
                            </Item>
                        )
                    ))}
                </StyledMenu>
            )}
        </div>
    );
};