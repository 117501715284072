import {Typography} from 'antd';
import {forwardRef} from 'react';
import styled, {css} from 'styled-components';
import {COLORS, screenSize} from '../../helpers/constants';

const {Text: TextComponent} = Typography;

export const textSizeMedia = css`
  @media (max-width: ${screenSize.xxl}) {
    font-size: 15px;
    line-height: 1.3;
  }
`;

export const textStyles = css`
  font-weight: 400;
  ${textSizeMedia}
`;

// eslint-disable-next-line no-unused-vars
export const Text = styled(forwardRef(({color, ...props}, ref) => <TextComponent {...props} ref={ref}/>))`
  && {
    ${textStyles}
    color: ${(props) => props.color || COLORS.PRIMARY.GRAY_DARK};
  }
`;

// eslint-disable-next-line no-unused-vars
export const SmallText = styled(({color, ...props}) => <Text {...props} />)`
  && {
    font-family: "Grapalat", Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: ${(props) => props.color || '#091024'};
  }
`;

// eslint-disable-next-line no-unused-vars
export const BoldText = styled(({color, decoration, ...props}) => <Text {...props} />)`
  && {
    font-family: Noto Sans Armenian;
    text-decoration: ${(props) => props.decoration || ''};;
    font-weight: bold;
    font-size: 19px;
    line-height: normal;
    color: ${(props) => props.color || '#1B9CBE'};
  }
`;

// eslint-disable-next-line no-unused-vars
export const PartTitle = styled(({color, decoration, ...props}) => <Text {...props} />)`
  && {
    text-decoration: ${(props) => props.decoration || ''};
    font-family: Noto Sans Armenian;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    margin: 57px 33px;
    color: ${(props) => props.color || '#1B9CBE'};
    
    @media screen and (max-width: 768px) {
      font-size: 30px;
      margin: 20px 10px;
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const SecondaryText = styled(({color, ...props}) => <Text type="secondary" {...props} />)`
  && {
    font-family: "Noto Sans Armenian";
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: ${(props) => props.color || '#456FAE'};
    
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 15px;
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const FooterText = styled(({color, ...props}) => <Text type="footer" {...props} />)`
  && {
    font-family: "Noto Sans Armenian";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: ${(props) => props.color || '#456FAE'};
    
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 12px;
    }
  }
`;


// eslint-disable-next-line no-unused-vars
export const PrintText = styled(({color, ...props}) => <Text {...props} />)`
  && {
    font-weight: 700;
    font-size: 17px;
    line-height: normal;
    margin-right: 20px;
    color: ${(props) => props.color || '#264655'};
  }
`;