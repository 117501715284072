import {Col, Form, Row, Space} from "antd";
import {COLORS, DICTIONARY_URL} from "../../helpers/constants";
import styled from "styled-components";
import {TopTitle} from "../typography/title";
import Background from "./images/footer.svg";
import {messages} from "../constant";
import MnhaInput from "../input";
import {SubmitButton} from "../button";
import GridModal from "../grid-modal/GridModal";
import {CheckCircleOutlined} from "@ant-design/icons";
import {useState} from "react";

const StyledDiv = styled.div`
    padding:  0 45px 40px;
    border-radius: 28px;
    height: 70%;
    display: flex;
    align-items: center;
    
  @media (max-width: 576px) {
    margin: 20px;
    padding: 25px;
    border-radius: 14px;
  }
`;

const defaultStyle = {
    background: `url(${Background})`,
    backgroundSize: "cover",
    height: "fit-content",
    marginTop: 110,
}

const formStyle = {
    borderRadius: "20px",
    background: "#FFF",
    boxShadow: "0px 80.6px 105.701px 0px rgba(51, 51, 51, 0.10)",
    padding: 40,
}

export const ContactUs = () => {
    const [openModal, setOpenModal] = useState(false);
    const [getConfirmText, setConfirmText] = useState();
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const onFinish = (values) => {
        fetch(`${BASE_URL}${DICTIONARY_URL.POST_USER_DATA}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                setConfirmText(data)
                setOpenModal(true)
                // Handle the response as needed
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const hideModal = () => {
        setOpenModal(false);
        window.location.reload()
    };

    return (
        <>
            <div style={defaultStyle}>
                <StyledDiv>
                    <Row gutter={[56, 16]} align={"middle"}>
                        <Col xs={24} sm={10} md={12} lg={14}>
                            <Space direction={"vertical"}>
                                <TopTitle color={COLORS.PRIMARY.WHITE}>ՄՆՀԱԱ</TopTitle>
                                <TopTitle color={COLORS.PRIMARY.WHITE}>
                                    Մասնավոր
                                    նախադպրոցական հաստատությունների
                                    աջակցման ասոցացիա
                                </TopTitle>
                            </Space>
                        </Col>
                        <Col xs={24} sm={14} md={12} lg={10}>
                            <div style={formStyle}>
                                <Form
                                    name="application-form"
                                    onFinish={onFinish}
                                >
                                    <Row justify={"center"} align={"middle"}>
                                        <Col span={24}>
                                            <Form.Item
                                                name={'full_name'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: messages.required
                                                    },
                                                ]}
                                            >
                                                <MnhaInput placeholder={"Անուն Ազգանուն"}/>
                                            </Form.Item>
                                            <Form.Item
                                                name={'facility_name'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: messages.required
                                                    },
                                                ]}
                                            >
                                                <MnhaInput placeholder={"Հաստատության անվանում"}/>
                                            </Form.Item>
                                            <Form.Item
                                                name={'email'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: messages.required,
                                                    },
                                                    {
                                                        type: 'email',
                                                        message: messages.incorrectFormat
                                                    },
                                                ]}
                                            >
                                                <MnhaInput placeholder={"Էլեկտրոնային հասցե"}/>
                                            </Form.Item>
                                            <Form.Item name={'text'}>
                                                <MnhaInput.TextArea placeholder={"Տեքստ"}/>
                                            </Form.Item>
                                            <SubmitButton htmlType="submit">
                                                Ուղարկել նամակ
                                            </SubmitButton>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </StyledDiv>
            </div>
            <GridModal
                title={
                    <h3>
                        <CheckCircleOutlined
                            tooltip="Ծանուցում"
                            style={{ color: COLORS.PRIMARY.YELLOW, fontSize: '25px', marginRight: '7px' }}
                        />{' '}
                        Ծանուցում
                    </h3>
                }
                open={openModal}
                onOk={hideModal}
                onCancel={hideModal}
                footer={false}
            >
                <p>{getConfirmText ? getConfirmText : ''}</p>
            </GridModal>
        </>
    )
}