import { Col, Row, Space } from "antd";
import { COLORS, DICTIONARY_URL } from "../../helpers/constants";
import styled from "styled-components";
import { TopTitle } from "../typography/title";
import MnhaButton from "../button";
import { ArrowRightOutlined } from "@ant-design/icons";
import Childrens from "./images/news.svg";
import { SecondaryText } from "../typography";
import useGetDictionary from "../../api/dictionary/useGetDictionary";

const StyledDiv = styled.div`
  margin: 20px 65px;
  padding: 45px 40px;
  border-radius: 28px;

  @media (max-width: 576px) {
    margin: 20px;
    padding: 25px;
    border-radius: 14px;
  }
`;

const defaultStyle = {
  width: "80%",
  display: "flex",
  alignItems: "center",
  borderRadius: "10px",
  padding: "10px",
  background: COLORS.PRIMARY.WHITE,
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
};

export const News = () => {
  const { data } = useGetDictionary(DICTIONARY_URL.GET_NEWS);

  return (
    <div style={{ background: COLORS.PRIMARY.YELLOW }}>
      <StyledDiv>
        <Row gutter={[16, 16]} justify={"space-between"}>
          <Col md={24} lg={12}>
            <Row>
              <Space direction={"vertical"}>
                <Col span={24}>
                  <TopTitle color={COLORS.PRIMARY.GRAY_DARK}>
                    {data?.title}
                  </TopTitle>
                </Col>
                <Col span={24}>
                  <SecondaryText color={"#545050"}>
                    {data?.description}
                  </SecondaryText>
                </Col>
              </Space>
            </Row>
            <Row style={{ marginTop: 40 }}>
              <Col span={24}>
                <a href={data?.link} rel="noopener" target="_blank">
                  <MnhaButton background={COLORS.PRIMARY.BLUE}>
                    Իմանալ ավելին <ArrowRightOutlined />
                  </MnhaButton>{" "}
                </a>
              </Col>
            </Row>
          </Col>
          <Col md={10} lg={10}>
            <div style={defaultStyle}>
              <img
                style={{ width: "100%", borderRadius: 16 }}
                src={data?.image}
              />
            </div>
          </Col>
        </Row>
      </StyledDiv>
    </div>
  );
};
