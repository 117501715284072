import React, {useState} from 'react';
import {RouterProvider} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

import locale from 'antd/lib/locale/hy_AM';
// import 'antd/dist/antd.min.css';
import {ConfigProvider} from 'antd';
import {router} from "./router";

const ReactQueryDevtoolsProduction = React.lazy(() =>
    import('react-query/devtools').then((module) => ({
        default: module.ReactQueryDevtools,
    }))
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const Root = () => {
    const [showDevtools, setShowDevtools] = useState(false);

    React.useEffect(() => {
        window.toggleDevtools = () => setShowDevtools((old) => !old);
    }, []);

    return (
        <ConfigProvider
            locale={locale}
            form={{
                colon: false,
                scrollToFirstError: true,
                // eslint-disable-next-line
                autoComplete: 'off',
                requiredMark: <span style={{color: 'red'}}>*</span>,
            }}
        >
                <QueryClientProvider client={queryClient}>
                    <RouterProvider router={router}/>
                    {showDevtools && (
                        <React.Suspense fallback={null}>
                            <ReactQueryDevtoolsProduction/>
                        </React.Suspense>
                    )}
                </QueryClientProvider>
        </ConfigProvider>
    );
};

export default Root;
