import React from 'react';
import {Col, Row} from 'antd';
import Logo from './Logo';
import MainHeader from './MainHeader';
import styled from 'styled-components';
import {COLORS, PATHS} from "../../helpers/constants";
import {MyMenu} from "../head-line/menu";
import {Link} from "react-router-dom";

const MainWhitePart = styled.div`
    position: absolute;
    bottom: 0;
    background-color: ${COLORS.PRIMARY.WHITE_GRAY}
`;

const DefaultHeader = () => {

    return (
        <MainHeader>
            <Row gutter={6} justify={'space-between'}>
                <Col>
                    <Link to={PATHS.ROOT}>
                        <Logo/>
                    </Link>
                </Col>
                <Col>
                    <MyMenu/>
                </Col>
            </Row>
            <MainWhitePart>
                {/*stex lini spitak masy*/}
            </MainWhitePart>
        </MainHeader>
    );
};

export default DefaultHeader;
