import {Button, Col, Result, Row, Space, Spin} from "antd";
import {SecondaryText} from "../../components/typography";
import {PageTitle} from "../../components/typography/title";
import useGetDictionary from "../../api/dictionary/useGetDictionary";
import {COLORS, DICTIONARY_URL, PATHS} from "../../helpers/constants";
import {Link, useLocation} from "react-router-dom";
import {SmileOutlined} from "@ant-design/icons";
import {PartTitle} from "../../components/typography/text";
import {useEffect} from "react";

export const Post = () => {
    const location = useLocation();
    const path = location.pathname;
    const directories = path.split("/");
    const slug = directories[(directories.length - 1)];
    const {data: postView, isLoading} = useGetDictionary(DICTIONARY_URL.GET_POST_VIEW, {enabled: true}, {post: slug});

    useEffect(() => {
        // Scroll to the generated unique ID block
        const element = document.getElementById("post");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }, [postView]);

    return (
        <Spin spinning={isLoading}>
            <PartTitle>{postView.title}</PartTitle>
            {Object.keys(postView).length ? (
                <Row style={{width: "100%", marginTop: 30}} justify={"center"} gutter={16} id={"post"}>
                    <Col xs={22} sm={22} md={10} lg={10} style={{background: COLORS.PRIMARY.YELLOW, padding: 20, borderRadius: 10, height: "fit-content"}}>
                        <img src={postView.image} style={{width: "100%"}} alt=""/>
                    </Col>
                    <Col xs={22} sm={22} md={10} lg={10} style={{background: COLORS.PRIMARY.WHITE_GRAY, padding: 20}}>
                        <Space direction={"vertical"}>
                            <PageTitle>{postView.title}</PageTitle>
                            <SecondaryText>
                                <div dangerouslySetInnerHTML={{__html: postView.description}}/>
                            </SecondaryText>
                        </Space>
                    </Col>
                </Row>
            ) : (
                <Result
                    title="Էջը պատրաստման փուլում է"
                    icon={<SmileOutlined style={{color: COLORS.PRIMARY.YELLOW}}/>}
                    extra={
                        <Button type="primary">
                            <Link to={PATHS.ROOT}>Գլխավոր էջ</Link>
                        </Button>
                    }
                />
            )}
        </Spin>
    );
}