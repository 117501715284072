import {Route, createBrowserRouter, createRoutesFromElements} from 'react-router-dom';
import {PATHS} from "./helpers/constants";
import {GeneralPage} from "./pages/general-page";
import {PublicRoutes} from "./utils/PublicRoutes";
import {Post} from "./pages/post";
import {Posts} from "./pages/posts";

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<PublicRoutes/>}>
            <Route path={PATHS.ROOT} element={<GeneralPage/>}/>
            <Route path={`${PATHS.POSTS}/:slug`} element={<Posts/>}/>
            <Route path={`${PATHS.POST}/:slug`} element={<Post/>}/>
        </Route>
    )
);