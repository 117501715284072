export const PATHS = {
    ROOT: '/',
    POSTS: '/posts',
    POST: '/post',
    ERROR_403: '/no-access',
};

export const DICTIONARY_URL = {
    GET_MENU_ITEMS: "/api/categories",
    GET_POSTS: "/api/posts",
    GET_POST_VIEW: "/api/post-view",
    INTERVIEWS: "/api/interviews ",
    POST_USER_DATA: "/api/contact",
    GET_NEWS: "/api/news",
    GET_GENERAL_POST: "/api/post-general",
};

export const REQUEST_TYPES = {
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
    GET: 'get',
};

export const COLORS = {
    PRIMARY: {
        LIGHT_BLACK: '#4B4D4F',
        BLUE: '#1B9CBE',
        LIGHT_GREEN: '#94d4cb',
        YELLOW: '#FDB713',
        RED_DARK: '#74032F',
        WHITE: '#FFFFFF',
        WHITE_DARK: '#F9F9F9',
        WHITE_GRAY: '#F6F6F6',
        BLACK: '#091024',
        GRAY_DARK: '#545050',
        GRAY_MIDLIGHT: '#717B8A',
        GRAY_LIGHT: '#E9E9E9',
    },

    SECONDARY: {
        BLUE_DARK: "#1748d0",
        YELLOW: '#F5B452',
        YELLOW_LIGHT: '#EDE06D',
        YELLOW_DARK: '#E8964A',
        RED: '#810A0A',
    },

    ALERT: {
        RED: '#ed1b1b',
        GRAY: '#808080',
        GREEN: '#5ACA75',
        WHITE_LIGHT: '#F2F2F2',
    },
};

export const screenSize = {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
};