import {Button, Col, Result, Row, Space, Spin} from "antd";
import MnhaPagination from "../../components/pagination";
import {SecondaryText} from "../../components/typography";
import {COLORS, DICTIONARY_URL, PATHS} from "../../helpers/constants";
import useGetDictionary from "../../api/dictionary/useGetDictionary";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {SmileOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";

export const Posts = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;
    const directories = path.split("/");
    const slug = directories[(directories.length - 1)];
    const [currentPage, setCurrentPage] = useState(1);
    const [hoveredItem, setHoveredItem] = useState(null);
    const {data: postList, isLoading} = useGetDictionary(DICTIONARY_URL.GET_POSTS, {enabled: true}, {
        pageNumber: currentPage,
        sub_category: slug
    });

    useEffect(() => {
        // Scroll to the generated unique ID block
        const element = document.getElementById("posts");
        if (element) {
            element.scrollIntoView({behavior: "smooth"});
        }
    }, [postList]);

    return (
        <Spin spinning={isLoading}>
            <Row justify={"center"} style={{width: "100%", marginTop: 30, padding: 50}} gutter={[16, 16]} id={"posts"}>
                {postList?.posts?.length ? postList.posts.map((item) => (
                        <Col xs={24} sm={20} md={11} lg={7} xl={5} xxl={4} key={item.id}
                             style={{
                                 border: `10px solid ${COLORS.PRIMARY.BLUE}`,
                                 borderRadius: 10,
                                 margin: "0 10px",
                                 padding: 0
                             }}>
                            <div
                                style={{
                                    position: "relative",
                                    height: "330px",
                                    textAlign: "center",
                                    background: `url(${item.image})`,
                                    backgroundSize: "100% auto",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    cursor: "pointer",
                                }}
                                onMouseEnter={() => setHoveredItem(item.id)}
                                onMouseLeave={() => setHoveredItem(null)}
                            >
                                {hoveredItem === item.id && (
                                    <button
                                        style={{
                                            position: "absolute",
                                            top: 30,
                                            left: 30,
                                            background: COLORS.PRIMARY.YELLOW,
                                            color: COLORS.PRIMARY.WHITE,
                                            padding: "8px 16px",
                                            borderRadius: 4,
                                            border: "none",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            navigate(`${PATHS.POST}/${item.slug}`, {state: {slug: item.slug}});
                                        }}
                                    >
                                        Դիտել
                                    </button>
                                )}
                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: 10,
                                        width: "100%",
                                        background: "rgba(27,156,190,0.70)",
                                    }}
                                >
                                    <SecondaryText color={COLORS.PRIMARY.WHITE}>
                                        {item.title}
                                    </SecondaryText>
                                </div>
                            </div>
                        </Col>
                    ))
                    :
                    <Result
                        title="Էջը պատրաստման փուլում է"
                        // subTitle="Sorry, the page you visited does not exist."
                        icon={<SmileOutlined style={{color: COLORS.PRIMARY.YELLOW}}/>}
                        extra={
                            <Button type="primary">
                                <Link to={PATHS.ROOT}>Գլխավոր էջ</Link>
                            </Button>
                        }
                    />
                }
            </Row>
            <Row justify="end" align="bottom">
                <MnhaPagination setCurrentPage={setCurrentPage} total={postList.totalRecords}/>
            </Row>
        </Spin>
    )
}