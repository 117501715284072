import { Col, Row, Space } from "antd";
import { TopTitle } from "../typography/title";
import Childrens from "./images/childrens.svg";
import Mnha from "./images/mnha.svg";
import styled from "styled-components";
import { useEffect, useState } from "react";
import {
  COLORS,
  DICTIONARY_URL,
  PATHS,
  screenSize,
} from "../../helpers/constants";
import MnhaButton from "../button";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { NewsLine } from "./news-line";
import useGetDictionary from "../../api/dictionary/useGetDictionary";

const StyledDiv = styled.div`
  padding: 45px 65px;

  @media (max-width: 576px) {
    margin: 20px;
    padding: 25px;
    border-radius: 14px;
  }
`;

export const HeadLine = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const { data } = useGetDictionary(DICTIONARY_URL.GET_GENERAL_POST);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ background: COLORS.PRIMARY.WHITE_GRAY }}>
      {location.pathname === PATHS.ROOT && data?.general === 1 && (
        <NewsLine data={data} />
      )}
      <StyledDiv>
        <Row gutter={0} align={"top"}>
          <Col md={24} lg={10}>
            <Row>
              <Col span={24}>
                <Space direction={"vertical"}>
                  <TopTitle color={COLORS.PRIMARY.BLUE}>ՄՆՀԱԱ</TopTitle>
                  <TopTitle color={COLORS.PRIMARY.BLUE}>
                    Մասնավոր նախադպրոցական հաստատությունների աջակցման ասոցիացիա
                  </TopTitle>
                </Space>
              </Col>
            </Row>
            <Row justify={"center"} style={{ marginTop: 90 }}>
              <Col span={14}>
                <TopTitle color={COLORS.PRIMARY.YELLOW}>Աջակցում</TopTitle>
              </Col>
            </Row>
            <Row justify={"center"}>
              <Col span={6}>
                <TopTitle color={COLORS.PRIMARY.BLUE}>Միավորում</TopTitle>
              </Col>
            </Row>
            <Row justify={"end"}>
              <Col span={10}>
                <TopTitle color={COLORS.PRIMARY.YELLOW}>Հզորացում</TopTitle>
              </Col>
            </Row>
            {location.pathname === PATHS.ROOT && (
              <Row style={{ marginTop: 90 }}>
                <Col span={24}>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdPgE2gIt2U-ZDNNhjJMcjiMu7pZw0bnETFhbrxj6EfqcKvFw/viewform"
                    target="_blank"
                  >
                    {" "}
                    <MnhaButton>
                      Միացի՛ր մեզ <ArrowRightOutlined />
                    </MnhaButton>{" "}
                  </a>
                </Col>
              </Row>
            )}
          </Col>
          {windowWidth > screenSize.md && (
            <Col md={22} lg={14}>
              <img
                style={{ width: "100%", height: "479px", borderRadius: 16 }}
                src={location.pathname === PATHS.ROOT ? Childrens : Mnha}
              />
            </Col>
          )}
        </Row>
      </StyledDiv>
    </div>
  );
};
