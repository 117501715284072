import styled, { css } from 'styled-components';
import { Input } from 'antd';

const defaultStyle = css`
    width: 100%;
    height: 65px;
    border-radius: 5px;
    border: 1.5px solid var(--Dark, #0A142F);
    opacity: 0.38;
    background: var(--White, #FFF);

  :active {
    border: 1px solid #3c9d99;
  }
  :hover {
    border: 1px solid #3c9d99;
  }
  :focus {
    border: 1px solid #3c9d99;
    box-shadow: none;
  }
  &::placeholder {
    font-weight: bold;
    color: #6C7282;
  }
`;

const MnhaInput = styled(Input)`
  && {
    ${defaultStyle}
  }
`;

const TextArea = styled(Input.TextArea)`
  && {
    height: 180px;
    border-radius: 1px;
    
    :active {
      border: 1px solid #3c9d99;
    }
    :hover {
      border: 1px solid #3c9d99;
    }
    :focus {
      border: 1px solid #3c9d99;
      box-shadow: none;
    }
    
    &::placeholder {
      font-weight: bold;
      color: #6C7282;
    }
  }
`;

MnhaInput.TextArea = TextArea;

const Password = styled(Input.Password)`
  &.ant-input-password {
    ${defaultStyle}
  }
`;

MnhaInput.Password = Password;

export default MnhaInput;