import styled from 'styled-components';
import { Modal } from 'antd';

const GridModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 10px;
    }

    .ant-modal-header {
        border-radius: 10px 10px 0 0;
        padding-bottom: 0;
        border-bottom: 0;
    }
`;

export default GridModal;
