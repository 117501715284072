import { useQuery } from '@tanstack/react-query';

import client from '../client';

const useGetDictionary = (url, options = { enabled: true }, params = {}) => {
    const result = useQuery({
        queryKey: [url, params],
        queryFn: () => client.get(url, { params }),
        ...options,
        select: (data) => data.data,
    });
    const { data, isSuccess } = result;
    return {
        ...result,
        data: isSuccess ? data : [],
    };
};

export default useGetDictionary;
