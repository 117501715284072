import { Layout } from 'antd';
import styled, { css } from 'styled-components';
import {COLORS} from "../../helpers/constants";

export const BackgroundLayout = styled(Layout)`
  ${(props) =>
    props.inner
      ? css`
          
        `
      : css`
          font-family: Noto Sans Armenian;
          background-color: ${COLORS.PRIMARY.WHITE};
        `}
`;


export const MainLayout = ({children}) => {
    return (
        <Layout style={{ minHeight: 'fit-content'}}>
            <BackgroundLayout>
                {children}
            </BackgroundLayout>
        </Layout>
    )
}