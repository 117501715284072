import styled from 'styled-components';
import {COLORS} from "../../helpers/constants";

const MainHeader = styled.div`
  background-color: ${COLORS.PRIMARY.WHITE};
  padding: 19px 20px;
  
  .headerPart {
    text-align: center;
  }
`;

export default MainHeader;
