import {OurActivities} from "../../components/our-activities";
import {PartTitle} from "../../components/typography/text";
import {News} from "../../components/news";

export const GeneralPage = () => {

    return (
        <>
            <PartTitle>Մեր գործունեությունը</PartTitle>

            <OurActivities/>

            {/*News*/}
            <News/>
        </>
    )
}