import styled from 'styled-components';
import { Button } from 'antd';
import {COLORS} from "../../helpers/constants";

const MnhaButton = styled(Button)`
  border-radius: 30px;
  background: ${COLORS.PRIMARY.GRAY_LIGHT};
  color: ${COLORS.PRIMARY.GRAY_DARK} !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 242px;
  height: 48px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Noto Sans Armenian;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  
  &.ant-btn-primary {
    background-color: ${COLORS.PRIMARY.BLUE};
    color: ${COLORS.PRIMARY.WHITE};
    border: 2px solid ${COLORS.PRIMARY.BLUE};
    
    &:hover {
      background-color: ${COLORS.PRIMARY.BLUE} !important;
      color: ${COLORS.PRIMARY.WHITE} !important;
      border: 2px solid #0b847f;
    }
  }

  &.ant-btn-default {
    color: ${COLORS.PRIMARY.WHITE};

   &:hover {
      border: none;
      background-color: ${(props) => props.background || COLORS.PRIMARY.YELLOW};
    }
  }
`;

export default MnhaButton;

export const SubmitButton = styled(Button)`
  background: ${COLORS.PRIMARY.YELLOW};
  color: ${COLORS.PRIMARY.WHITE};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 170px;
  height: 54px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Noto Sans Armenian;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  
  &.ant-btn-primary {
    background-color: ${COLORS.PRIMARY.BLUE};
    color: ${COLORS.PRIMARY.WHITE};
    border: 2px solid ${COLORS.PRIMARY.BLUE};
    
    &:hover {
      background-color: ${COLORS.PRIMARY.BLUE} !important;
      color: ${COLORS.PRIMARY.WHITE} !important;
      border: 2px solid #0b847f;
    }
  }

  &.ant-btn-default {
    color: ${COLORS.PRIMARY.WHITE};

   &:hover {
      border: none;
      background-color: ${(props) => props.background || COLORS.PRIMARY.YELLOW};
    }
  }
`;
