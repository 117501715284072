import styled, {css} from 'styled-components';
import {COLORS} from "../../helpers/constants";

const Title = styled.span`
    color: ${(props) => props.color};
    font-family: Noto Sans Armenian;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  
  @media only screen and (max-width: 768px) {
    font-size: 24px;
    height: 140px;
  }
`;

export const TopTitle = styled(({ color, ...props }) => <Title {...props} />)`
  && {
    color: ${(props) => props.color};
    font-family: Noto Sans Armenian;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  
  @media only screen and (max-width: 768px) {
    font-size: 24px;
    height: 140px;
  }
  }
`;

export const PageTitle = ({children}) => {
    return <Title>{children}</Title>;
};
