import React, { useState, useEffect } from 'react';
import './ToTopButton.css';
import {UpOutlined} from "@ant-design/icons"; // You can create a CSS file for styling

const ToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Function to scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    // Function to check scroll position and toggle visibility of the button
    const handleScroll = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Add scroll event listener when component mounts
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <button className={`to-top-button ${isVisible ? 'show' : 'hide'}`} onClick={scrollToTop}>
            <span className="arrow"><UpOutlined /></span>
        </button>
    );
};

export default ToTopButton;
