import { Col, Row, Space } from "antd";
import { COLORS } from "../../helpers/constants";
import styled from "styled-components";
import { TopTitle } from "../typography/title";
import MnhaButton from "../button";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const StyledDiv = styled.div`
  margin: 20px 65px;
  padding: 45px 40px;
  border-radius: 28px;

  @media (max-width: 576px) {
    margin: 20px;
    padding: 25px;
    border-radius: 14px;
  }
`;

const defaultStyle = {
  width: "80%",
  display: "flex",
  alignItems: "center",
  borderRadius: "10px",
  padding: "10px",
  background: COLORS.PRIMARY.WHITE,
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
};

export const NewsLine = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div style={{ background: COLORS.PRIMARY.YELLOW }}>
      <StyledDiv>
        <Space direction={"vertical"}>
          <Row gutter={[16, 16]} justify={"space-between"}>
            <Col md={24} lg={12}>
              <Row>
                <Col span={24}>
                  <TopTitle color={COLORS.PRIMARY.GRAY_DARK}>
                    {data?.title}
                  </TopTitle>
                </Col>
              </Row>
              <Row style={{ marginTop: 40 }} align={"bottom"}>
                <Col span={24}>
                  <MnhaButton
                    background={COLORS.PRIMARY.BLUE}
                    onClick={() => navigate("/post/" + data?.slug)}
                  >
                    Իմանալ ավելին <ArrowRightOutlined />
                  </MnhaButton>{" "}
                </Col>
              </Row>
            </Col>
            <Col md={10} lg={10}>
              <div style={defaultStyle}>
                <img
                  style={{ width: "100%", borderRadius: 16 }}
                  src={data?.image}
                />
              </div>
            </Col>
          </Row>
        </Space>
      </StyledDiv>
    </div>
  );
};
