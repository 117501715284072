import {Button, Col, Row, Space} from "antd";
import {COLORS, PATHS, screenSize} from "../../helpers/constants";
import styled from "styled-components";
import Founders from "./images/founders.svg";
import Purpose from "./images/purpose.svg";
import Members from "./images/members.svg";
import Conference from "./images/conference.svg";
import Seminars from "./images/seminars.svg";
import {BoldText} from "../typography/text";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const StyledDiv = styled.div`
  padding: 40px 0;
  background: ${COLORS.PRIMARY.WHITE_GRAY};
  border-radius: 28px;

  @media (max-width: 576px) {
    padding: 25px;
    border-radius: 14px;
    width: 100%;
  }
`;

const StyledDivSec = styled.div`
  padding: 20px;
  background: ${COLORS.PRIMARY.YELLOW};
  border-radius: 10px;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};

  @media (max-width: 576px) {
    border-radius: 14px;
  }
`;

const defaultStyle = {
    textAlign: "center",
    background: COLORS.PRIMARY.WHITE,
    borderRadius: "10px",
    width: "100%",
    height: "100%",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
};

export const OurActivities = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        windowWidth >= screenSize.xl ? (
            <StyledDiv>
                <Row justify={"center"} gutter={[16, 16]} style={{width: "100%"}}>
                    <Col span={5}>
                        <Row justify={"start"}>
                            <Col span={24} style={{height: "660px"}}>
                                <StyledDivSec>
                                    <div style={defaultStyle}>
                                        <Button
                                            style={{
                                                marginTop: 80,
                                                height: 60,
                                                background: COLORS.PRIMARY.GRAY_LIGHT,
                                            }}
                                            onClick={() => navigate(`${PATHS.POSTS}/himnadirner`)}
                                        >
                                            <BoldText>ՀԻՄՆԱԴԻՐՆԵՐ</BoldText>
                                        </Button>
                                        <img style={{marginTop: 180, width: "90%"}} src={Founders} alt=""/>
                                    </div>
                                </StyledDivSec>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={7}>
                        <Space direction={"vertical"}>
                            <Row>
                                <Col span={24}>
                                    <StyledDivSec height={"327px"}>
                                        <div style={defaultStyle}>
                                            <Space direction={"vertical"}>
                                                <Button
                                                    style={{
                                                        marginTop: 20,
                                                        height: 60,
                                                        background: COLORS.PRIMARY.GRAY_LIGHT,
                                                    }}
                                                    onClick={() => navigate(`${PATHS.POST}/mer-masin`)}
                                                >
                                                    <BoldText>ՄԵՐ ՄԱՍԻՆ</BoldText>
                                                </Button>
                                                <img style={{marginTop: 30}} src={Purpose} alt=""/>
                                            </Space>
                                        </div>
                                    </StyledDivSec>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <StyledDivSec height={"327px"}>
                                        <div style={defaultStyle}>
                                            <img style={{marginTop: 20}} src={Members} alt=""/>
                                            <Button
                                                style={{
                                                    marginTop: 20,
                                                    height: 60,
                                                    background: COLORS.PRIMARY.GRAY_LIGHT,
                                                }}
                                                onClick={() => navigate(`${PATHS.POSTS}/liirav-andamner`)}
                                            >
                                                <BoldText>ԼԻԻՐԱՎ ԱՆԴԱՄՆԵՐ</BoldText>
                                            </Button>
                                        </div>
                                    </StyledDivSec>
                                </Col>
                            </Row>
                        </Space>
                    </Col>
                    <Col span={5}>
                        <Space direction={"vertical"}>
                            <Row justify={"center"}>
                                <Col span={24}>
                                    <StyledDivSec height={"399px"}>
                                        <div style={defaultStyle}>
                                            <Button
                                                style={{
                                                    marginTop: 50,
                                                    height: 60,
                                                    background: COLORS.PRIMARY.GRAY_LIGHT,
                                                }}
                                                onClick={() => navigate(`${PATHS.POSTS}/hamazhvoghvov`)}
                                            >
                                                <BoldText>ՀԱՄԱԺՈՂՈՎ</BoldText>
                                            </Button>
                                            <img style={{marginTop: 40}} src={Conference} alt=""/>
                                        </div>
                                    </StyledDivSec>
                                </Col>
                            </Row>
                            <Row justify={"center"}>
                                <Col span={24}>
                                    <StyledDivSec height={"257px"}>
                                        <div style={defaultStyle}>
                                            <Space direction={"vertical"}>
                                                <Button
                                                    style={{
                                                        marginTop: 10,
                                                        height: 50,
                                                        background: COLORS.PRIMARY.GRAY_LIGHT,
                                                    }}
                                                    onClick={() => navigate(`${PATHS.POSTS}/mankavarzhneri-hamar`)}
                                                >
                                                    <BoldText>ԴԱՍԸՆԹԱՑ</BoldText>
                                                </Button>
                                                <img style={{marginTop: 20}} src={Seminars} alt=""/>
                                            </Space>
                                        </div>
                                    </StyledDivSec>
                                </Col>
                            </Row>
                        </Space>
                    </Col>
                </Row>
            </StyledDiv>
        ) : (
            <Row justify={"center"} gutter={[16, 16]} style={{width: "100%"}}>
                <Col xs={24} sm={12} md={10}>
                    <StyledDivSec>
                        <div style={defaultStyle}>
                            <Button
                                style={{
                                    marginTop: 50,
                                    height: 60,
                                    background: COLORS.PRIMARY.GRAY_LIGHT,
                                }}
                                onClick={() => navigate(`${PATHS.POSTS}/himnadirner`)}
                            >
                                <BoldText>ՀԻՄՆԱԴԻՐՆԵՐ</BoldText>
                            </Button>
                            <img style={{marginTop: 50, width: "100%"}} src={Founders} alt=""/>
                        </div>
                    </StyledDivSec>
                </Col>
                <Col xs={24} sm={12} md={10}>
                    <StyledDivSec>
                        <div style={defaultStyle}>
                            <Space direction={"vertical"}>
                                <Button
                                    style={{
                                        width: "fit-content",
                                        marginTop: 20,
                                        height: 60,
                                        background: COLORS.PRIMARY.GRAY_LIGHT,
                                    }}
                                    onClick={() => navigate(`${PATHS.POST}/mer-masin`)}
                                >
                                    <BoldText>ՄԵՐ ՄԱՍԻՆ</BoldText>
                                </Button>
                                <img style={{marginTop: 30}} src={Purpose} alt=""/>
                            </Space>
                        </div>
                    </StyledDivSec>
                </Col>
                <Col xs={24} sm={12} md={9}>
                    <StyledDivSec>
                        <div style={defaultStyle}>
                            <Space direction={"vertical"}>
                                <img style={{marginTop: 30, width: "80%"}} src={Members} alt=""/>
                                <Button
                                    style={{
                                        marginBottom: 20,
                                        height: 60,
                                        background: COLORS.PRIMARY.GRAY_LIGHT,
                                    }}
                                    onClick={() => navigate(`${PATHS.POSTS}/liirav-andamner`)}
                                >
                                    <BoldText>ԼԻԻՐԱՎ ԱՆԴԱՄՆԵՐ</BoldText>
                                </Button>
                            </Space>
                        </div>
                    </StyledDivSec>
                </Col>
                <Col xs={24} sm={12} md={9}>
                    <StyledDivSec>
                        <div style={defaultStyle}>
                            <Button
                                style={{
                                    marginTop: 50,
                                    height: 60,
                                    background: COLORS.PRIMARY.GRAY_LIGHT,
                                }}
                                onClick={() => navigate(`${PATHS.POSTS}/hamazhvoghvov`)}
                            >
                                <BoldText>ՀԱՄԱԺՈՂՈՎ</BoldText>
                            </Button>
                            <img style={{marginTop: 40, width: "80%"}} src={Conference} alt=""/>
                        </div>
                    </StyledDivSec>
                </Col>
                <Col xs={24} sm={12} md={12} style={{marginBottom: 30}}>
                    <StyledDivSec>
                        <div style={defaultStyle}>
                            <Button
                                style={{
                                    marginTop: 10,
                                    height: 50,
                                    background: COLORS.PRIMARY.GRAY_LIGHT,
                                }}
                                onClick={() => navigate(`${PATHS.POSTS}/mankavarzhneri-hamar`)}
                            >
                                <BoldText>ԴԱՍԸՆԹԱՑ</BoldText>
                            </Button>
                            <img style={{marginTop: 20, width: "80%"}} src={Seminars} alt=""/>
                        </div>
                    </StyledDivSec>
                </Col>
            </Row>
        )
    );
};
